import styled from 'styled-components'

const Styling = styled.div`
    .gatsby-image-wrapper{
    width:100%;
    max-width: 70rem;
    margin:0 auto 4rem auto;

        @media only screen and (min-width: 1024px) {
            margin:0 auto 8rem auto;
        }

    }
`

export { Styling }