import React from 'react'
import { Styling } from './styled.js'
import parse from 'html-react-parser'
import Quotes from '../../../../images/quotes.inline.svg';

export default function Quote({data}) {
    return (
        <Styling  className="flex">
            <Quotes />
            <div className="quote">{parse(data.quoteText)}</div>
        </Styling>
    )
}
