import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';


export default function ImageBlock({data}) {
    const theImage = getImage(data?.image?.localFile);
    return (
        <Styling className="flex">
            <GatsbyImage image={theImage} layout="fullWidth" className="image" alt={'test'} />              
        </Styling>
    )
}
