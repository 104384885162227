import styled from 'styled-components'

const Styling = styled.div`
position: relative;
width: 100%;
display: block;
padding:0 2rem;
margin-bottom: 4rem;

    @media only screen and (min-width: 350px) {
    padding:0 4rem;
    }

    @media only screen and (min-width: 1024px) {
        padding:0 8rem;
        margin-bottom: 8rem;
    }


    .videoWrapper{
    padding-bottom: 56.25%; /* 16:9 */
    position: relative;
    height: 0;
    width: 100%;
    background: var(--black);

        iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        }
    }
`

export { Styling }