import React from 'react'
import { Styling } from './styled.js'
import parse from 'html-react-parser'



export default function TextBlock({data}) {
    return (
        <Styling>
            <div className="videoWrapper">{parse(data.video)}</div>
        </Styling>
    )
}
