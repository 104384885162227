import React, {useState, useEffect, useRef} from 'react'
import { Styling } from './styled.js'
import Play from '../../../images/play_audio.inline.svg'
import Pause from '../../../images/pause_audio.inline.svg'
import { gsap } from "gsap";

export default function AudioPlayer({audioFile}) {


    const [audio, setAudio] = useState();
    
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0)
    const [currentTimeFormatted, setCurrentTimeFormatted] = useState('0:00')
    const [duration, setDuration] = useState(0)
    const [durationFormatted, setDurationFormatted] = useState(0)
    const [percentagePlayed, setPercentagePlayed] = useState(0)
    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        setAudio( 
            new Audio(
                audioFile?.file?.localFile?.publicURL 
                || audioFile?.audioFile?.localFile?.publicURL
            )
        )

        
        return () => {
        }
    }, [])
    
    useEffect(() => {
        
        return () => {
            if(audio !== undefined){
                audio.pause()
            }
        }
    }, [audio])


    useEffect(() => {
        if(audio){
            playing ? audio.play() : audio.pause();
        }

        if(playing){
            setInterval(function(){
                const current = Math.floor(audio.currentTime)
                const mins = Math.round(Math.floor(current/ 60))
                const secs = Math.floor(current % 60)
                setCurrentTime(current);
                setPercentagePlayed(100/duration * current)
                setCurrentTimeFormatted(`${mins}:${('0' + secs).slice(-2)}`);
            },1000)
        }
    },[playing, audio]);
    
    useEffect(() => {
        if(playing){
            gsap.to('.progress', {width:percentagePlayed+'%'})
        }
    },[playing, percentagePlayed]);

    useEffect(() => {
        if(audio){

            audio.addEventListener('ended', () => setPlaying(false));
            audio.addEventListener('loadedmetadata', (e) => {
                const mins = Math.round(Math.floor(e.target.duration / 60))
                const secs = Math.floor(e.target.duration % 60)
                setDuration(e.target.duration);
                setDurationFormatted(`${mins}:${('0' + secs).slice(-2)}`);
            });
            return () => {
                audio.removeEventListener('ended', () => setPlaying(false));
            };
        }
    }, [audio]);

    const calculateScrubBar = (e) => {
        let rect = e.target.closest('.bar_wrap').getBoundingClientRect();
        let width = rect.width
        let x = e.clientX - rect.left; //x position within the element.
        let percent = 100 / width * x
        // setPercentagePlayed(percent)
        gsap.to('.progress', {width:percent+'%'})
        audio.currentTime = duration/100 * percent
    }

    return (
        <Styling className="audio_player_wrapper flex">
            <div className="audio_player">
                <div className="play" onClickCapture={toggle}>{playing ? <Pause/> : <Play />}</div>
                <div className="controls">
                    <div className="title">{audioFile.fileName || audioFile.trackTile}</div>
                    <div className="bar_wrap" onClick={(e) => calculateScrubBar(e)}>
                        <div className="progress"></div>
                    </div>
                    <div className="time">
                        <div className="currentTime">{currentTimeFormatted}</div>
                        <div className="totalTime">{durationFormatted}</div>
                    </div>
                </div>
            </div>
        </Styling>
    )
}
