import styled from 'styled-components'

const Styling = styled.div`
background:var(--grey_light);
display: flex;
position: relative;

    a{
    width: 100%;
    display: flex;
    text-decoration: none;
    color: inherit;
    flex-wrap: wrap;

        
        @media only screen and (min-width: 550px) {
        flex-wrap: nowrap;
        }

        
        .image-wrapper{
        width: 100%;
        height: 100%;

            @media only screen and (min-width: 550px) {
            width: 50%;
            }

            .gatsby-image-wrapper{
            width: 100%;
            height: 100%;
            }
        
            img{
            ${({theme}) => theme.easing('0.5')}  
            }
        
        }
        
        .info{
        width: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        padding:8rem;
        ${({theme}) => theme.easing('0.5')}  


            @media only screen and (max-width: 550px) {
            position: absolute;
            top:0;
            left:0;
            height: 100%;
            background:rgba(0,0,0,.5);
            color:var(--white);
            }

            @media only screen and (min-width: 550px) {
            width: 50%;
            }

            .label{
            width: 100%;
            margin-bottom: 4rem;
            text-transform: uppercase;
            ${({theme}) => theme.tomato('bold')}  
            ${({theme}) => theme.easing('0.5')}  
            }

            .title{
            margin-bottom: 4rem;
            width: 100%;
            text-transform: uppercase;
            letter-spacing: -0.09em;
            font-size:clamp(3rem, 3vw, 8rem);
            ${({theme}) => theme.easing('0.5')}  
            }
            
            .capsule_button{
                @media only screen and (max-width: 550px) {
                    background: var(--white);
                    color:var(--black);
                    border:1px solid var(--white);
                    }
            }

            .capsule_button:hover{
                background: transparent;
                color:var(--black);
                border:1px solid var(--black);
            }
        }

        &:hover{
            .image-wrapper img{
                transform: scale(1.2) rotate(4deg);
            }
            .info{
            background:var(--black);

            @media only screen and (max-width: 550px) {
                background:rgba(0,0,0,0.8))
            }
            
                .title,
                .label{
                color:var(--white);
                }
                .capsule_button{
                background: var(--black);
                color:var(--white);
                border:1px solid var(--white);
                    @media only screen and (max-width: 550px) {
                    background: var(--white);
                    color:var(--black);
                    border:1px solid var(--white);
                    }
                }

            }
        }
    }

`

export { Styling }