import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
background:var(--black);
color:var(--white);
padding:10rem 0 0 0;

    @media only screen and (min-width: 768px) {
    padding: clamp(6rem,10vw,20rem) 0 0 8rem;
    }
    .inner{
    max-width:1400px;
    margin:0 auto;
    display: flex;
    flex-wrap: wrap;

        @media only screen and (min-width: 768px) {
        flex-wrap: nowrap;
        }

    }
 
    .image-wrapper{
    width: calc(100% - 8rem);
    margin-left:4rem;
    order:2;

        @media only screen and (min-width: 768px) {
        width:45%;
        margin-left:0;
        order:1;
        }

        .gatsby-image-wrapper{
        border-radius: 0.5rem;
        overflow: hidden;
        height:100%;

            @media only screen and (min-width: 1150px) {
            height: 0;
            padding-bottom: 80%;
            }

        }

    /* margin-top:8rem; */
    }

    .heroContent{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    order:1;
    padding:10rem 4rem 0 4rem;
    
        @media only screen and (min-width: 768px) {
        padding:10rem 4rem;
        width: 55%;
        order:2;
        }



        @media only screen and (min-width: 1150px) {
        padding:10rem;
        }

        .mainTitle{
        margin-bottom:4rem;
        margin-top:-8rem;
        width: 100%;
        position: relative;
        ${({theme}) => theme.tomato('regular')}  
        text-transform:uppercase;
        font-size:4rem;
        letter-spacing:-0.09em;
        }
        
        .text{
        margin-bottom:4rem;
        width: 100%;
        position: relative;
        ${({theme}) => theme.telegraf('regular')}  
        font-size: 1.6rem;
        line-height:2.5rem;
        }

    
    }

`

export { Styling }