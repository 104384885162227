import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';

export default function FeaturesPostHero({hero}) {
    
    const theImage = getImage(hero?.blog_post_fields?.blogHero?.image?.localFile || hero?.acf_hero?.heroImage?.localFile);
    return (
        <Styling>
            <div className="inner">
                <div className="image-wrapper">
                    <GatsbyImage image={theImage} className="image" alt={'test'} />
                </div>
                <div className="heroContent">
                    <h2 className="mainTitle">{hero.title}</h2>
                    <div className="text">{hero?.blog_post_fields?.blogHero?.introText || hero?.cookiesPrivacyIntroText?.introTextCookiesPrivacy}</div>
                </div>
            </div>
        </Styling>
    )
}
