import React from 'react'
import FeaturedPostHero from '../components/page-components/FeaturesPost/FeaturesPostHero'
import FlexibleContent from '../components/reusable/FlexibleContent'
import NextPost from '../components/page-components/FeaturesPost/NextPost'
import { graphql } from "gatsby"
import {Styling} from '../styles/templates/features-post.styled'
import Seo from '../components/global/seo'

export default function FeaturesPost({data}) {

    return (
        <Styling>
          <Seo title={data.hero.seo.title} description={data.hero.seo.metaDesc} />
          <FeaturedPostHero hero={data.hero} />
          <FlexibleContent flexible={data.flexible} />
          <NextPost nextPost={data.nextPost} />
        </Styling>
    )
}


export const query = graphql`
    query($id: String!, $next: String!){
        nextPost: wpPost(id: {eq: $next}) {
            title
            link
            blog_post_fields {
                blogHero {
                    image {
                        localFile{
                          childImageSharp {
                            gatsbyImageData(
                              width: 1500
                              placeholder: BLURRED
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                    }
                }
            }
        }
        hero: wpPost(id: {eq: $id}) {
            title
            seo{
              metaDesc
              title
            }
            blog_post_fields {
                blogHero {
                    introText
                    image {
                        localFile{
                          childImageSharp {
                            gatsbyImageData(
                              width: 1500
                              placeholder: BLURRED
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                    }
                }
            }
        }
        flexible: wpPost(id: {eq: $id}){
            flexibleContent {
                postBuilder {
                  ... on WpPost_Flexiblecontent_PostBuilder_TextBlock {
                    fieldGroupName
                    text
                  }
                  ... on WpPost_Flexiblecontent_PostBuilder_Quote {
                    fieldGroupName
                    quoteText
                  }
                  ... on WpPost_Flexiblecontent_PostBuilder_AudioPlayer {
                    fieldGroupName
                    trackTile
                    audioFile {
                      localFile {
                        publicURL
                      }
                    }
                  }
                  ... on WpPost_Flexiblecontent_PostBuilder_ImageBlock {
                    fieldGroupName
                    image {
                        localFile{
                            childImageSharp {
                              gatsbyImageData(
                                width: 1500
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                                layout: FULL_WIDTH
                              )
                            }
                          }
                    }
                  }
                  ... on WpPost_Flexiblecontent_PostBuilder_VideoBlock {
                    fieldGroupName
                    video
                  }
                }
              }
        }
    }
`