import styled from 'styled-components'

const Styling = styled.div`

    .flexible-content-wrapper{
    width:100%;
    background:var(--white);

        .flexible-content-inner{
        width:100%;
        max-width: 1200px;
        margin:0 auto;
        }
    
    }
`

export { Styling }