import styled from 'styled-components'

const Styling = styled.div`
padding:12rem 0 8rem 0;
background: var(--white);
margin-top:-8rem;

    @media only screen and (min-width: 1024px) {
        padding:16rem 0 8rem 0;
    }

    .audio_player_wrapper{
    margin-bottom: 8rem;
    padding:0 8rem;
    }

`

export { Styling }