import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import { Link } from 'gatsby';

export default function NextPost({nextPost}) {
const theImage = getImage(nextPost?.blog_post_fields?.blogHero?.image?.localFile);
    return (
        <Styling>
            <Link to={nextPost.link}>
                <div className="image-wrapper">
                    <GatsbyImage image={theImage} className="image" alt={'test'} />
                </div>
                <div className="info">
                    <div className="label">Read next</div>
                    <div className="title">{nextPost.title}</div>
                    <button className="capsule_button transparent dark">Read more</button>
                </div>
            </Link>
        </Styling>
    )
}
