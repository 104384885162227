import styled from 'styled-components'

const Styling = styled.div`
text-transform:uppercase;

    @media only screen and (min-width: 350px) {
    padding:0 4rem;
    margin-bottom:4rem;
    font-size:2.5rem;
    }
    @media only screen and (min-width: 768px) {
    font-size:3rem;
    }


    @media only screen and (min-width: 1024px) {
    padding:0 8rem;
    margin-bottom: 8rem;
    font-size:clamp(3rem, 2vw, 8rem);
    line-height:clamp(3rem*1.25, 2vw*1.25, 8rem*1.25);
    letter-spacing: -0.06em;
    }

    svg{
    margin-bottom: 2rem;
    }

`

export { Styling }