import styled from 'styled-components'

const Styling = styled.div`
width: 100%;

    .audio_player{
    width: 100%;
    margin-bottom: 4rem;
    padding:2rem;
    box-shadow: 0px 25px 56px rgba(0, 0, 0, 0.06), 0px 12.6562px 24.4125px rgba(0, 0, 0, 0.0405), 0px 5px 9.1px rgba(0, 0, 0, 0.03), 0px 1.09375px 3.2375px rgba(0, 0, 0, 0.0195);
    border-radius: 8px;
    display: flex;
    align-items: center;

        .play{
        width:5.6rem;
        height:5.6rem;
        margin-right:2rem;
        cursor: pointer;
        }

        .time{
        width: 100%;
        display: flex;
        justify-content: space-between;
        }
        .controls{
        width:100%;

            .title{
            margin-bottom: 1rem;
            }

        }

        .pause_icon{
        visibility: hidden;
        }

        .bar_wrap{
        width: 100%;
        height:5px;
        background:var(--grey_light);
        cursor: pointer;
        margin-bottom: 1rem;

            .progress{
            width:0;
            height:5px;
            background:var(--black);
            position: relative;

                &:after{
                position: absolute;
                right:-3px;
                top:-5px;
                height:15px;
                width:3px;
                background:var(--black);
                content:'';
                }

            }

        }

    }
`

export { Styling }